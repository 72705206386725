/* eslint-disable */
const config = {
  apiHost: "https://slidespielportalsapitest.azurewebsites.net",
  spmApiHost: "https://slidespielspeakersapi.azurewebsites.net",
  portalPrice: 40,
  portalPriceForMembers: 30,
  stripeKey: "pk_test_0hlApLH5MBAWQzgK4nfccP1H",
  url: "",
  env: "development",
  portalName: "sahms-ce",
  title: "SAHMS 2021 Virtual Conference",
  hasCme: false,
  questionsBeforeRecording: false,
  portals: [
    { year: 2020, id: "0b49ae1d-e942-40d3-b31a-fcda8242ba97" },
  ],
  defaultYear: 2020,
  // amplitudeKey: "5a691f5d28c551997691879d728c1da7",
  version: "0.1.1",
};

if (process.env.NODE_ENV === "production") {
  Object.assign(config, {
    "apiHost": "https://slidespielportalsapi.azurewebsites.net",
    "stripeKey": "pk_live_nJvbydZp1mBjTIkW4mzW33B5",
    "env": "production",
    "sentryDsn": "https://66ebec47e0014dccb50af5e51f5348eb@o361175.ingest.sentry.io/5264442",
  });
}


export default config;
