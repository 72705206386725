import config from "./config";
import jQuery from "jquery";
import superagent from 'superagent';

const portalId = config.portals.find(x => x.year === config.defaultYear).id;
const defaultPortalName = config.portalName + "-" + config.defaultYear;

const api = {
  getUser(id = "me", accessToken, portalName) {
    return this.req("get", `api/user/${id}`, null, accessToken, portalName);
  },

  login(username, password, id = portalId) {
    //console.log(id)
    const data = {
      portalId: id,
      grant_type: "password",
      username,
      password
    }
    return this.req("post", "token", data)
  },

  logOut(portalName) {
    console.log("newApi", "removing token");
    localStorage.removeItem(`${portalName}-access_token`);
  },

  register(email, firstName, lastName, token, portalId, portalName) {
    return this.req("post", "api/user/register", {
      firstName,
      lastName,
      email,
      portalId,
      token,
    }, null, portalName)
  },

  registerWithEvent(data) {
    return this.req("post", "api/v2/user/register", data);
  },

  buyProduct(data) {
    return this.req("post", "api/user/buyproduct", data);
  },

  getEventPrice(entityId, data) {
    const url = `api/v2/price/${entityId}`;
    return this.req("post", url, data).then(x => x.body);
  },

  getProductPrice(entityId, data) {
    const url = `api/v3/price/${entityId}`;
    return this.req("post", url, data).then(x => x.body);
  },

  resetPassword(email, portalId) {
    const data = {
      portalId,
      email,
    }
    return this.req("post", "api/user/resendaccess", data)
  },

  getSchedule(eventId) {
    return this.speakerReq(`event/${eventId}/schedule`);
  },

  speakerReq(path, method = 'get', data, axiosOpts = {}) {
    const url = `${config.spmApiHost}/api/${path}`;
    return fetch(url).then(response => response.json());
  },

  getLogins() {
    return this.req("get", "api/analytics/logins").then(x => x.body);
  },

  getLoginsCsv() {
    return this.reqBin(`api/analytics/logins/csv`);
  },
  
  getSignUpsCsv(id) {
    return this.reqBin(`api/analytics/signupdata/${id}`);
  },

  getEventConnectionTrackerDataCsv(eventId) {
    return this.reqBin(`api/analytics/event/${eventId}/connectionTracking`);
  },

  getSessionEvaluationDataCsv(id) {
    return this.reqBin(`api/analytics/evaluation/${id}`);
  },

  getSales(days = 1) {
    return this.req("get", "api/analytics/sales?days=" + days).then(x => x.body);
  },

  getSalesCsv() {
    return this.reqBin(`api/analytics/sales/csv`);
  },

  getLastCertificates() {
    return this.req("get", "api/analytics/certificates").then(x => x.body);
  },

  getLastCertificatesCsv() {
    return this.reqBin(`api/analytics/certificates/csv`);
  },

  getMembers(searchString, portalName) {
    return this.req("get", "api/members?value=" + searchString, null, null, portalName);
  },

  addMember(email, portalName) {
    return this.req("post", "api/member?email=" + email, null, null, portalName);
  },

  deleteMember(email, portalName) {
    return this.req("delete", "api/member?email=" + email, null, null, portalName);
  },

  getUsers(searchString, portalId, portalName) {
    return this.req("get", `api/portal/${portalId}/users?value=${searchString}`, null, null, portalName);
  },

  addUser(data, portalName) {
    return this.req("post", "api/portal/users", data, null, portalName);
  },

  deleteUser(userId, portalName) {
    return this.req("delete", "api/portal/users/" + userId, null, null, portalName);
  },

  changePassword(data, portalName) {
    return this.req("post", "api/user/changepassword", data, null, portalName);
  },

  buyRecording(recordingId, token, portalName) {
    return this.req("post", "api/user/buyrecording", { recordingId, token }, null, portalName);
  },

  getEvents() {
    return this.req("get", `api/portal/${portalId}/events`);
  },

  getPortal() {
    return this.req("get", `api/portal/${portalId}`).then(x => x.body);
  },

  getExhibitors(eventId) {
    return this.req("get", `api/event/${eventId}/exhibithall`).then(x => x.body);
  },

  getExhibitor(exhibitorId) {
    return this.req("get", `api/exhibit/${exhibitorId}`).then(x => x.body);
  },

  getExhibitorFile(exhibitorId, fileId, onProgress) {
    return this.reqBin(`api/exhibit/${exhibitorId}/file/${fileId}`, null, onProgress);
  },

  getChatUserId(uid) {
    return this.req("post", "api/chat/user", { uid }).then(x => x.body);
  },

  getUserEvents(userId) {
    return this.req("get", `api/user/${userId}/events`).then(x => x.body);
  },

  getSessionForEvaluation(eventId) {
    return this.req("get", `api/event/${eventId}/sessionsevaluate/test`).then(x => x.body);
  },

  evaluateSession(data) {
    return this.req("post", "api/sessionevaluation", data);
  },
  
  askSpeaker(recordingId, question) {
    return this.req("post", `/api/speakerquestion/recording/${recordingId}`, { question });
  },

  addUserToEvent(eventId, userId) {
    return this.req("post", `api/event/${eventId}/users/${userId}`);
  },

  removeUserFromEvent(eventId, userId) {
    return this.req("delete", `api/event/${eventId}/users/${userId}`);
  },

  registerForEvent({ eventId, token, signUpFormData, upgradeAccess, promoCode, pricing }) {
    const data = token ? { token, signUpFormData, promoCode, upgradeAccess, pricing } : { signUpFormData };
    return this.req("post", `api/user/me/events/${eventId}`, data);
  },

  postTrackerData(data) {
    return this.req("post", 'api/user/watching', data);
  },

  getTracks() {
    return this.req("get", `api/portal/${portalId}/preview`);
  },

  getTracksLogged(portalName, accessToken = null) {
    return this.req("get", "api/portal/content", null, accessToken, portalName);
  },

  getCertificates(portalName) {
    return this.req("get", "api/user/certificates", null, null, portalName);
  },

  getCertificate(recordingId) {
    return this.reqBin(`api/user/certificate/${recordingId}`);
  },
  callMeLater(exhibitorId) {
    return this.req("post", `api/exhibit/${exhibitorId}/callmelater`);
  },
  callMeNow(exhibitorId, representativeId, data) {
    return this.req("post", `api/exhibit/${exhibitorId}/${representativeId}/callmenow`, data);
  },
  trackChat(exhibitorId, representativeId) {
    return this.req("post", `api/exhibit/${exhibitorId}/${representativeId}/chat`);
  },
  requestContent(portalName, data) {
    return this.req("post", "api/exhibit/request-content", data, null, portalName);
  },

  reqBin(path, accessToken, onProgress = () => {}) {
    const url = `${config["apiHost"]}/${path}`,
          token = accessToken ? accessToken : localStorage.getItem(`${defaultPortalName}-access_token`);
    return (
      superagent
        .get(url)
        .set({ "Authorization": `Bearer ${token}` })
        .on("progress", onProgress)
        .responseType('blob')
    );
  },

  req(method, path, data, accessToken, portalName = defaultPortalName) {
    console.log("req portal name", path, portalName);
    const url = `${config["apiHost"]}/${path}`,
          token = accessToken ? accessToken : localStorage.getItem(`${portalName}-access_token`);
    return (
      superagent(method, url)
        .send(data)
        .set({
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          "Accept": "*/*",
          "Authorization": `Bearer ${token}`
        })
    );
  },

  getRecordingQuiz(recordingId, portalName) {
    return this.oldReq("get", `testing/${recordingId}`, null, null, null, portalName);
  },

  setQuizReset(recordingId, portalName) {
    return this.oldReq("post", `testing/${recordingId}/proceed/1`, null, null, null, portalName);
  },

  setRecordingQuizStep(recordingId, portalName) {
    return this.oldReq("post", `testing/${recordingId}/next`, null, null, null, portalName);
  },

  setRecordingQuizFeedback(recordingId, feedback, portalName) {
    return this.oldReq("post", `testing/${recordingId}/next`, { feedback}, null, null, portalName);
  },

  postRecordingQuizAnswer(recordingId, answerId, portalName) {
    return this.oldReq("post", `testing/${recordingId}/answer/${answerId}`, null, null, null, portalName);
  },

  oldReq(method, path, data, ajaxSettings={}, token = localStorage.getItem(defaultPortalName + "-access_token"), portalName) {
    console.log("old api", path, portalName);
    if (portalName)
      token = localStorage.getItem(`${portalName}-access_token`)
    const url = `${config["apiHost"]}/api/${path}`;
    return jQuery.ajax({...{
      headers: { Authorization: `Bearer ${token}` },
      method,
      data,
      url
    }, ...ajaxSettings});
  },

  postUserData(data) {
    const dataUrl = new URLSearchParams(data).toString();
    console.log(dataUrl);
    const url = 'https://script.google.com/macros/s/AKfycbx65pN922XgKDAdYdKglXcjJK9TlV_0t9nODFr9Aep-6SAxOIlA/exec?' + dataUrl;
    return fetch(url);
  },
};

export default api;
